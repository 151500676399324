import { useEffect, useRef, useState } from "react";

// Custom Hook to observe intersection (visibility in viewport)
export const useIntersectionObserver = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Element is in the viewport
      }
    });

    // Observe the element if it's available
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    // Cleanup observer when component unmounts or ref changes
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return [isVisible, elementRef]; // Return the visibility state and the reference
};
