import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const Contacts = () => {
    const [queries, setQueries] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(null);

    useEffect(() => {
        fetchQueries();
    }, []);

    const fetchQueries = () => {
        axios.get(`${BASE_URL}/api/queries/list/`)
            .then(response => setQueries(response.data))
            .catch(error => console.error(error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this query?')) {
            axios.delete(`${BASE_URL}/api/queries/delete/${id}/`)
                .then(() => {
                    fetchQueries();  // Re-fetch queries after deletion
                    alert('Query deleted successfully');
                })
                .catch(error => console.error('Error deleting query:', error));
        }
    };

    const handleSelectQuery = (query) => {
        setSelectedQuery(query); // Set selected query
    };

    const handleCloseDetails = () => {
        setSelectedQuery(null); // Close details view
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Client Queries</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {queries.map(query => (
                    <div key={query.id} className="bg-white p-4 shadow-md rounded-lg">
                        <h2 className="text-xl font-semibold mb-2">
                            {query.query_type === 'return' ? `Return Request` : `Information Request`} #{query.id}
                        </h2>
                        <p className="text-sm text-gray-600 mb-1"><strong>Client:</strong> {query.client_name}</p>
                        <p className="text-sm text-gray-600 mb-1"><strong>Email:</strong> {query.email}</p>
                        <button
                            onClick={() => handleSelectQuery(query)}
                            className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                            View Details
                        </button>
                        <button
                            onClick={() => handleDelete(query.id)}
                            className="ml-4 bg-gradient-to-r from-red-600 to-red-800 text-white px-4 py-2 rounded hover:bg-red-700"
                        >
                            Delete Query
                        </button>
                    </div>
                ))}
            </div>

            {/* Selected Query Details */}
            {selectedQuery && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg h-3/4 overflow-y-auto relative">
                        <button
                            onClick={handleCloseDetails}
                            className="absolute top-4 right-4 text-4xl font-bold text-gray-600 hover:text-gray-900"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl font-bold mb-4">
                            {selectedQuery.query_type === 'return' ? `Return Request` : `Information Request`} #{selectedQuery.id}
                        </h2>
                        <p><strong>Client:</strong> {selectedQuery.client_name}</p>
                        <p><strong>Email:</strong> {selectedQuery.email}</p>
                        <p><strong>Message:</strong> {selectedQuery.message}</p>
                        <p><strong>Created At:</strong> {new Date(selectedQuery.created_at).toLocaleString()}</p>

                        {selectedQuery.query_type === 'return' && selectedQuery.command && (
                            <div className="mt-4">
                                <h3 className="text-md font-medium mb-2">Order Details</h3>
                                <p><strong>Order ID:</strong> {selectedQuery.command.command_id}</p>
                                <p><strong>City:</strong> {selectedQuery.command.city}</p>
                                <p><strong>Street:</strong> {selectedQuery.command.street}</p>
                                
                                <p><strong>Total Amount:</strong> {selectedQuery.command.total_amount} TND</p>

                                <div className="mt-4">
                                    <h4 className="text-md font-medium mb-2">Products:</h4>
                                    {selectedQuery.command.command_products.map((item, index) => (
                                        <div key={index} className="mb-2">
                                            <img
                                                src={`${BASE_URL}${item.product.product_images[0]?.image}`}
                                                alt={item.product.product_name}
                                                className="w-20 h-20 object-cover rounded-md mb-2"
                                            />
                                            <p><strong>Product Name:</strong> {item.product.product_name}</p>
                                            <p><strong>Price:</strong> {item.product.discounted_price} TND</p>
                                            <p><strong>Size:</strong> {item.size}</p>
                                            <p><strong>Color:</strong> {item.color}</p>
                                            <p><strong>Quantity:</strong> {item.quantity}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contacts;
