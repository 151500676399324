import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove tokens from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // Redirect to the admin login page
        navigate('/admin/login');  // Ensure the user is redirected here
    };

    return (
        <button 
            onClick={handleLogout} 
            className="bg-red-500 text-white font-semibold py-2 px-4 rounded hover:bg-red-600 transition duration-300"
        >
            Logout
        </button>
    );
};

export default Logout;
