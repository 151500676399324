import React from "react";
import { BiCaretDown } from "react-icons/bi";

const NavTitle = ({ title, icons }) => {
  return (
    <div className="flex items-center justify-between pb-4">
      <h3 className="font-bold text-lg text-black-600">{title}</h3>
      {icons && <BiCaretDown className="text-xl text-gray-600" />}
    </div>
  );
};

export default NavTitle;
