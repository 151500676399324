import React from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { motion } from "framer-motion";
import "./About.css"; // Custom CSS file for styling
import { ariabr3 } from "../../assets/images/index";
import { micky } from "../../assets/images/index";

const About = () => {
  // Variants for animations
  const imageVariants = {
    hidden: { opacity: 0, scale: 0.5 }, // Slightly scale down for effect
    visible: { opacity: 1, scale: 1 },
  };

  // Staggered text variants for animation
  const textVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="about-section" id="id">
      <motion.div
        className="image-container"
        initial="hidden"
        animate="visible"
        transition={{ duration: 2 }} // Duration for the image animation
        variants={imageVariants}
      >
        <img src={micky} alt="Summer Collection" />
      </motion.div>

      <motion.div
        className="text-container"
        initial="hidden"
        animate="visible"
        transition={{ duration: 1, delay: 2.5 }} // Delay the text animation to start after the image animation
        variants={textVariants}
      >
        <Breadcrumbs title="How the Story Begins" />

        {/* Staggered Animation for Paragraphs */}
        <motion.p
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.8, delay: 2 }} // First paragraph - slower
          variants={textVariants}
        >
          is a unique store that offers a thoughtfully curated selection of products designed to inspire and celebrate personal journeys. 
          Whether you're looking for a meaningful gift,or stylish clothing, the shop features items that tell a story and evoke a sense of adventure and individuality. 
          Each product reflects the spirit of beginning new chapters, making it perfect for those who embrace change and growth.
        </motion.p>

        <motion.p
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.9, delay: 2.8 }} // Second paragraph - slower
          variants={textVariants}
        >
          The shop aims to provide a seamless shopping experience, blending creativity and functionality. 
          It's more than just a store—it's a place where every product has a story to share, 
          helping you start or continue yours in style.
        </motion.p>

      
      </motion.div>
    </div>
  );
};

export default About;
