import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useDispatch } from "react-redux";
import { resetCart } from "../../redux/shopSlice";

const BASE_URL = process.env.REACT_APP_API_URL;

const Command = () => {
  const location = useLocation();
  const { products, totalAmt, shippingCharge } = location.state; // Get products and totals from state
  const dispatch = useDispatch();
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [street, setstreet] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    const commandData = {
      client_name: clientName,
      email,
      number,
      city,
      street,
      total_amount: totalAmt.toFixed(2),
      shipping_charge: shippingCharge.toFixed(2),
      command_products: products.map((product) => ({
        product: product._id, // Only pass the product_Id here
        size: product.size || null, // Default to null if no size
        color: product.color || null, // Default to null if no color
        quantity: product.quantity || 0, // Default to 0 if no quantity
      })),
    };

    try {
      const response = await fetch(`${BASE_URL}/api/commands/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commandData),
      });

      if (response.ok) {
        setSuccessMessage("Your order has been submitted successfully! Please review your email ");
        setErrorMessage(""); // Clear any previous error message
        setClientName("");
        setEmail("");
        setNumber("");
        setCity("");
        setstreet("");
        dispatch(resetCart());
      } else {
        throw new Error("Failed to submit the order. Please try again.");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage(""); // Clear any previous success message
    } finally {
      setIsLoading(false); // Reset loading state regardless of outcome
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 py-8">
      <Breadcrumbs title="Command" />
      {successMessage ? (
        <div>
          <p className="pb-12 w-96 font-medium text-green-500">{successMessage}</p>
          <Link to="/shop">
            <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
              Continue Shopping
            </button>
          </Link>
        </div>
      ) : (
        <div className="max-w-lg lg:max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-8 border border-gray-200">
          <h1 className="text-4xl font-bold mb-6 text-center">Complete Your Order</h1>

          {/* Order Summary */}
          <div className="mt-5">
            <h2 className="text-lg font-bold mb-4 text-center">Order Summary</h2>
            {products.map((product) => (
              <div key={product._id} className="flex items-center gap-4 border-b py-4">
                <img src={product.image} alt={product.name} className="w-20 h-20 object-cover" />
                <div>
                  <h2 className="text-lg font-semibold">{product.name}</h2>
                  <p>Color: {product.color}</p>
                  <p>Size: {product.size}</p>
                  <p>Quantity: {product.quantity}</p>
                  <p>Price: {product.price} TND</p>
                  <p>Discounted Price: {product.discounted_price} TND</p>
                </div>
              </div>
            ))}
          </div>

          {/* Totals */}
          <div className="mt-8 text-center">
            <p className="text-lg">Subtotal: {totalAmt.toFixed(2)} TND</p>
            <p className="text-lg">Shipping Charge: {shippingCharge.toFixed(2)} TND</p>
            <p className="text-lg font-bold">Total: {(totalAmt + shippingCharge).toFixed(2)} TND</p>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-6">
            <input
              type="text"
              placeholder="Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              required
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-primeColor"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-primeColor"
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-primeColor"
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-primeColor"
            />
            <input
              type="text"
              placeholder="Street"
              value={street}
              onChange={(e) => setstreet(e.target.value)}
              required
              className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-primeColor"
            />

            <button
              type="submit"
              className={`bg-primeColor text-white hover:bg-black duration-300 py-3 rounded-md font-semibold text-lg ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Please wait..." : "Submit Order"} {/* Change button text */}
            </button>
          </form>

          {/* Feedback Messages */}
          {errorMessage && (
            <div className="mt-4 text-red-500 font-semibold text-center">
              {errorMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Command;
