import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../../designLayouts/Image";
import { useIntersectionObserver } from "./useIntersectionObserver"; // Import hook from the same folder

const BASE_URL = process.env.REACT_APP_API_URL;
const Body = () => {
  const [isVisible1, ref1] = useIntersectionObserver();
  const [isVisible2, ref2] = useIntersectionObserver();
  const [isVisible3, ref3] = useIntersectionObserver();
  const [roundedImages, setroundedImages] = useState([]); // State for fetched images

  // Fetch images from the server
  useEffect(() => {
    fetch(`${BASE_URL}/api/images/rounded/`)
      .then((response) => response.json())
      .then((data) => {
        setroundedImages(data); // Assuming 'data' contains an array of image URLs
      })
      .catch((error) => console.error("Error fetching banner images:", error));
  }, []);


  return (
    <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      {/* First Image (From Left) */}
      <div
        ref={ref1}
        className={`w-[300px] md:w-[500px] lg:w-[500px] h-full transition-all duration-1000 ease-out transform ${
          isVisible1
            ? "opacity-100 translate-x-0 translate-y-0" // Element fully visible
            : "opacity-0 -translate-x-64 translate-y-20" // Start from left and fade-up
        }`}
      >
         <Link to="/shop">
          {roundedImages[0] && ( // Ensure the image exists before rendering
            <Image className="h-full w-full object-cover rounded-full" imgSrc={`${BASE_URL}${roundedImages[0].image}`} />
          )}
        </Link>
      </div>

      <div className="w-[300px] md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
        {/* Second Image (From Left) */}
        <div
          ref={ref2}
          className={` md:ml-12 lg:ml-12 h-1/2 w-[300px] lg:w-[500px] transition-all duration-1000 ease-out transform ${
            isVisible2
              ? "opacity-100 translate-x-0 translate-y-0" // Element fully visible
              : "opacity-0 -translate-x-64 translate-y-20" // Start from left and fade-up
          }`}
        >
          <Link to="/shop">
            {roundedImages[1] && ( // Ensure the image exists before rendering
              <Image className="w-full h-full object-contain rounded-full" imgSrc={`${BASE_URL}${roundedImages[1].image}`} />
            )}
          </Link>
        </div>

        {/* Third Image (From Right) */}
        <div
          ref={ref3}
          className={` md:ml-12 lg:ml-12  h-1/2 w-[300px] lg:w-[500px] transition-all duration-1000 ease-out transform ${
            isVisible3
              ? "opacity-100 translate-x-0 translate-y-0" // Element fully visible
              : "opacity-0 translate-x-64 translate-y-20" // Start from right and fade-up
          }`}
        >
          <Link to="/shop">
            {roundedImages[2] && ( // Ensure the image exists before rendering
              <Image className="w-full h-full object-contain rounded-full" imgSrc={`${BASE_URL}${roundedImages[2].image}`} />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Body;
