// src/components/admin/AdminLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try { 
            const response = await axios.post(`${BASE_URL}/accounts/login/`, { username, password });
            // Store access and refresh tokens in localStorage
            localStorage.setItem('accessToken', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
            // Optionally store an authenticated flag
         //   localStorage.setItem('isAdminAuthenticated', true);
            navigate('/admin');  // Redirect to dashboard
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (

<div className="w-full h-screen flex items-center justify-center">
<div className="w-full mdl:w-1/2 h-full lg:ml-56">

    <form className="w-full lgl:w-[450px] h-screen flex items-center justify-center" onSubmit={handleLogin}>
      <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
        <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4 sm:ml-24 lg:ml-0">
          Sign in

        </h1>
        <div className="flex flex-col gap-3">
          {/* Email */}
          <div className="flex flex-col gap-.5">
            <p className="font-titleFont text-base font-semibold text-gray-600">
              Username
            </p>
            <input
              onChange={(e) => setUsername(e.target.value)} 
              value={username}
              className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
              type="username"
              placeholder="Username"
            />
          </div>

          {/* Password */}
          <div className="flex flex-col gap-.5">
            <p className="font-titleFont text-base font-semibold text-gray-600">
              Password
            </p>
            <input
              onChange={(e) => setPassword(e.target.value)} 
              value={password}
              className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
              type="password"
              placeholder="Type your password"
            />
           
          </div>

          <button
            type="submit"
            className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
          >
            Sign In
          </button>
          {error && <p>{error}</p>}
        </div>
      </div>
    </form>

</div>
</div>
    );
};

export default AdminLogin;
