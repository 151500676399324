import React, { useState, useEffect } from "react"; 
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Image from "../designLayouts/Image";
import SampleNextArrow from "../home/Men/SampleNextArrow";
import SamplePrevArrow from "../home/Men/SamplePrevArrow";

const BASE_URL = process.env.REACT_APP_API_URL;

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const [bannerImages, setBannerImages] = useState([]); // State for fetched images

  // Fetch images from the server
  useEffect(() => {
    fetch(`${BASE_URL}/api/images/banner/`)
      .then((response) => response.json())
      .then((data) => {
        setBannerImages(data); // Assuming 'data' contains an array of image URLs
      })
      .catch((error) => console.error("Error fetching banner images:", error));
  }, []);

  const settings = {
    dots: true,
    infinite: bannerImages.length >= 4, // Allow infinite loop only if there are 4 or more banners
    autoplay: true,
    slidesToShow: 3, // Conditional number of slides
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "7%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: "30px",
                color: "#262626",
                borderRight: "3px #262626 solid",
                padding: "8px 0",
                cursor: "pointer",
              }
            : {
                width: "30px",
                color: "transparent",
                borderRight: "3px white solid",
                padding: "8px 0",
                cursor: "pointer",
              }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="md:mx-32 bg-white justify-center ">
      <Slider {...settings}>
        {bannerImages.map((image, index) => (
          <Link to="/shop" key={index}>
            <div className="h-[500px] lg:h-[600px]">
              <Image className="w-full h-full object-cover" imgSrc={`${BASE_URL}${image.image}`} />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
