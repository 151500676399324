import React, { useEffect, useState } from "react"; 
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const BASE_URL = process.env.REACT_APP_API_URL;

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [queryType, setQueryType] = useState("information");
  const [commandId, setCommandId] = useState("");

  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  const [errCommandId, setErrCommandId] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // New state for loading
  const [isLoading, setIsLoading] = useState(false);

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };
  
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const handleQueryType = (e) => {
    setQueryType(e.target.value);
    setErrCommandId("");
  };

  const handleCommandId = (e) => {
    setCommandId(e.target.value);
    setErrCommandId("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = (e) => {
    e.preventDefault();
  
    let isValid = true;
  
    if (!clientName) {
      setErrClientName("Enter your Name");
      isValid = false;
    }
    if (!email) {
      setErrEmail("Enter your Email");
      isValid = false;
    } else if (!EmailValidation(email)) {
      setErrEmail("Enter a Valid Email");
      isValid = false;
    }
    if (!messages) {
      setErrMessages("Enter your Message");
      isValid = false;
    }
  
    if (queryType === "return" && !commandId) {
      setErrCommandId("Enter a valid Command ID");
      isValid = false;
    }
  
    if (isValid) {
      setIsLoading(true); // Set loading state to true
  
      fetch(`${BASE_URL}/api/queries/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_name: clientName,
          email: email,
          query_type: queryType,
          message: messages,
          command_id: queryType === "return" ? commandId : null,
        }),
      })
        .then(async (response) => {
          const data = await response.json();
  
          if (!response.ok) {
            if (response.status === 404 && data.error === "Command ID does not exist") {
              setErrCommandId("Command ID does not exist");
            } else {
              console.error("Error:", data);
            }
          } else {
            setSuccessMsg(
              `Thank you dear ${clientName}, your message has been received successfully. Further details will be sent to you at ${email}.`
            );
            setClientName("");
            setEmail("");
            setMessages("");
            setCommandId("");
            setQueryType("information");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state
        });
    }
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Contact" prevLocation={prevLocation} />
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        <form className="pb-20">
          <h1 className="font-titleFont font-semibold text-3xl">Fill up a Form</h1>
          <div className="w-[500px] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">Name</p>
              <input
                onChange={handleName}
                value={clientName}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">Email</p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Enter your email here"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">Query Type</p>
              <select
                onChange={handleQueryType}
                value={queryType}
                className="w-full py-2 border-b-2 px-2 text-base font-medium outline-none focus-within:border-primeColor"
              >
                <option value="information">Information</option>
                <option value="return">Return</option>
              </select>
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">Message</p>
              <textarea
                onChange={handleMessages}
                value={messages}
                cols="30"
                rows="3"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                placeholder="Enter your message here"
              ></textarea>
              {errMessages && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errMessages}
                </p>
              )}
            </div>

            {queryType === "return" && (
              <div>
                <p className="text-base font-titleFont font-semibold px-2">Command ID</p>
                <input
                  onChange={handleCommandId}
                  value={commandId}
                  className="w-full py-1 border-b-2 px-2 text-base font-medium outline-none focus-within:border-primeColor"
                  type="text"
                  placeholder="Enter Command ID"
                />
                {errCommandId && (
                  <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                    <span className="text-sm italic font-bold">!</span>
                    {errCommandId}
                  </p>
                )}
              </div>
            )}

            <button
              onClick={handlePost}
              className={`w-44 h-10 font-titleFont text-base tracking-wide font-semibold ${isLoading ? "bg-gray-300" : "bg-primeColor hover:bg-black hover:text-white"} duration-200`}
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Post"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;
