import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "../Men/SampleNextArrow";
import SamplePrevArrow from "../Men/SamplePrevArrow";
import axios from "axios";

// Define your base URL once
const BASE_URL = process.env.REACT_APP_API_URL;

const Women = () => {
  const [products, setProducts] = useState([]); // State to hold product data

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/products/gender/Women/`);
        console.log("Fetched Products:", response.data); // Check the API response here
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  if (products.length === 0) {
    return null; // Return null to not render anything if there are no products
  }

  const settings = {
    infinite: products.length >= 4, // Infinite loop only if there are 4 or more products
    speed: 500,
    slidesToShow: 4, // Always show 4 slides
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: products.length >= 3, // Adjust infinite behavior for 3 products
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: products.length >= 2, // Adjust infinite behavior for 2 products
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true, // Allow infinite for small screens
        },
      },
    ],
  };

  return (
    <div className="w-full pb-20">
      <Heading heading="Women" />
      <Slider {...settings}>
        {products.map((product) => (
          <div
            className="px-2"
            key={product.product_Id}
            style={{
              width: "350px", // Fixed width
              height: "400px", // Fixed height to keep uniformity
            }}
          >
            <Product
              _id={product.product_Id}
              img={`${BASE_URL}${product.product_images[0]?.image}`} // Assuming the first image is the main image
              images={product.product_images}
              productName={product.product_name}
              price={product.price}
              promo_percentage={product.promo_percentage}
              discounted_price={product.discounted_price}
              colors={product.colors}
              badge={product.badge}
              des={product.description}
              gender={product.gender}
              season={product.season}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Women;
