import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/shopSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const products = useSelector((state) => state.shopReducer.products);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);

  useEffect(() => {
    let price = 0;
    products.forEach((item) => {
      price += item.discounted_price * item.quantity;
    });
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {
    if (totalAmt <= 200) {
      setShippingCharge(10);
    } else if (totalAmt <= 400) {
      setShippingCharge(8);
    } else if (totalAmt > 401) {
      setShippingCharge(7);
    }
  }, [totalAmt]);

  const handleCheckout = () => {
    // Map products to include relevant information
    const productList = products.map((product) => ({
      _id: product._id,
      name: product.productName,
      des: product.des,
      quantity: product.quantity,
      image: product.image,
      images:product.images,
      badge: product.badge,
      price: product.price,
      discounted_price:product.discounted_price, 
      colors:product.colors,
      color: product.color, // The selected color
      size: product.size,
      gender:product.gender,
      season:product.season, 
    }));

    // Navigate to the checkout/command page and pass the productList
    navigate("/command", {
      state: {
        products: productList, // Pass the list of products with details
        totalAmt,
        shippingCharge,
      },
    });
  };

  return (
    <div className="max-w-container mx-auto px-4">
  <Breadcrumbs title="Cart" />
  {products.length > 0 ? (
    <div className="pb-20">
      {/* Cart header with column labels */}
      <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-7 place-content-center px-6 text-lg font-titleFont font-semibold">
        <h2 className="col-span-2">Product</h2>
        <h2 className="col-span-1 text-center">Price</h2>
        <h2 className="col-span-1 text-center">Color</h2>
        <h2 className="col-span-1 text-center">Size</h2>
        <h2 className="col-span-1 text-center">Quantity</h2>
        <h2 className="col-span-1 text-center">Sub Total</h2>
      </div>

      {/* Product items */}
      <div className="mt-5">
        {products.map((item) => (
          <div key={item._id}>
            <ItemCard item={item} />
          </div>
        ))}
      </div>

          <button
            onClick={() => dispatch(resetCart())}
            className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300"
          >
            Reset cart
          </button>

          <div className="flex flex-col mdl:flex-row justify-between border py-4 px-4 items-center gap-2 mdl:gap-0">
            <div className="flex items-center gap-4">
              <input
                className="w-44 mdl:w-52 h-8 px-4 border text-primeColor text-sm outline-none border-gray-400"
                type="text"
                placeholder="Coupon Number"
              />
              <p className="text-sm mdl:text-base font-semibold">
                Apply Coupon
              </p>
            </div>
            <p className="text-lg font-semibold">Update Cart</p>
          </div>
          <div className="max-w-7xl gap-4 flex justify-end mt-4">
            <div className="w-96 flex flex-col gap-4">
              <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Subtotal
                  <span className="font-semibold tracking-wide font-titleFont">
                    {totalAmt.toFixed(2)} TND
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Shipping Charge
                  <span className="font-semibold tracking-wide font-titleFont">
                    {shippingCharge.toFixed(2)} TND
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                  Total
                  <span className="font-bold tracking-wide text-lg font-titleFont">
                    {(totalAmt + shippingCharge).toFixed(2)} TND
                  </span>
                </p>
              </div>
              <div className="flex justify-end">
                <button 
                  onClick={handleCheckout} // Call handleCheckout on click
                  className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Cart feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              dress, T-shirt, shoes, etc. and make it happy.
            </p>
            <Link to="/shop">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Continue Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Cart;
