
// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  } 
];

export const navBarList1 = [
  
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  
  {
    _id: 1003,
    title: "Contact",
    link: "/contact",
  },
  
];
// =================== NavBarList End here ======================
