// src/pages/AdminDashboard.js
import React from 'react';
import Logout from './Logout';

const AdminDashboard = () => {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold mb-4 text-center">Welcome to Admin Dashboard</h1>
            <Logout />
        </div>
    );
  };
  export default AdminDashboard;