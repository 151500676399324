import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const ProtectedAdminRoute = ({ component: Component }) => {
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Auth state
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                // Make a server request to verify the token
                await axios.get(`${BASE_URL}/accounts/protected-admin/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setIsAuthenticated(true);  // Set authenticated to true if token is valid
            } catch (error) {
                // Token validation failed, set auth to false
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);  // Stop loading after token check
            }
        };

        if (accessToken) {
            verifyToken();
        } else {
            setIsLoading(false);  // No token, stop loading
            setIsAuthenticated(false);  // Not authenticated
        }
    }, [accessToken]);

    if (isLoading) {
        return <div>Loading...</div>;  // Show loading spinner while verifying token
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" />;
    }

    return <Component />;
};

export default ProtectedAdminRoute;
