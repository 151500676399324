import React from "react";
import Category from "./shopBy/Category";

const ShopSideNav = ({ updateProducts, closeSidebar }) => {
  return (
    <div className="mt-8 w-full flex flex-col gap-6"> {/* Reduced width for large screens */}
      <Category gender="Men" updateProducts={updateProducts} closeSidebar={closeSidebar} />
      <Category gender="Women" updateProducts={updateProducts} closeSidebar={closeSidebar} />
    </div>
  );
};

export default ShopSideNav;
