import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import "aos/dist/aos.css";
import Command from "./pages/Command/Command";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import AdminLogin from "./components/admin/AdminLogin";
import AdminDashboard from "./components/admin/AdminDashboard";
import Products from "./components/admin/Products";
import Commands from "./components/admin/Commands";
import Contacts from "./components/admin/Contacts";
import ImageManagement from "./components/admin/ImageManagement";

import ProtectedAdminRoute from "./components/admin/ProtectedAdminRoute"; // Ensure admin route protection
import Sidebar from './components/admin/Sidebar';
const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
const AdminLayout = () => {
  return (
      <div className="md:flex md:h-screen md:relative">
          <Sidebar />
          <div className="md:flex-grow md:p-2 md:overflow-y-auto">
              <Outlet />
          </div>
      </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/Command" element={<Command />}></Route>
      </Route>
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin" element={<ProtectedAdminRoute component={AdminLayout} />} >
        <Route index element={<ProtectedAdminRoute component={AdminDashboard} />} />
        <Route path="products" element={<ProtectedAdminRoute component={Products} />} />
        <Route path="commands" element={<ProtectedAdminRoute component={Commands} />} />
        <Route path="contacts" element={<ProtectedAdminRoute component={Contacts} />} />
        <Route path="ImageManagement" element={<ProtectedAdminRoute component={ImageManagement} />} />
        
        {/* Other admin routes */}
      </Route>
    </Route>
   
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
