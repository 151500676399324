import React from "react";
import Product from "../../home/Products/Product"; // Assuming you have a Product component to display

const BASE_URL = process.env.REACT_APP_API_URL;

const Pagination = ({ products }) => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-6 text-center">Product List</h2>
      {products.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
          {products.map((product) => (
            <Product
              key={product.product_Id}
              _id={product.product_Id}
              img={`${BASE_URL}${product.product_images[0]?.image}`} // Assuming your API returns image URLs
              images={product.product_images}
              productName={product.product_name}
              price={product.price}
              promo_percentage={product.promo_percentage}
              discounted_price={product.discounted_price}
              colors={product.colors}
              badge={product.badge}
              des={product.description}
              gender={product.gender}
              season={product.season}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-64">
          <p className="text-lg font-medium text-gray-600">
            No products available for this category
          </p>
        </div>
      )}
    </div>
  );
};

export default Pagination;
