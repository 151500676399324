import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import Slider from "react-slick";
import SampleNextArrow from "../../components/home/Men/SampleNextArrow";
import SamplePrevArrow from "../../components/home/Men/SamplePrevArrow";
const BASE_URL = process.env.REACT_APP_API_URL;
const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState([]);

  useEffect(() => {
    setProductInfo(location.state.item);
    setPrevLocation(location.pathname);
  }, [location]);

  // Slider settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="h-full md:col-span-2 xl:col-span-2"> {/* Ensure it spans 2 columns on medium screens */}
          <Slider {...settings}>
              {/* Dynamically map through product images */}
              {productInfo.images && productInfo.images.map((image, index) => (
                <div key={index} className="h-full">
                  <img
                    className="w-full h-full object-cover"
                    src={`${BASE_URL}${image.image}`} // Use the `image` property from the array
                     alt={`Product ${index + 1}`}
                  />
                  
                </div>
                
              ))}
             
            </Slider>
            
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
