import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const Men = () => {
  const [products, setProducts] = useState([]); // State to hold product data
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/products/gender/Men/`); // Fetch products by gender (Men)
        setProducts(response.data); // Set the fetched products into state
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchProducts();
  }, []);

  if (products.length === 0) {
    return null; // Return null to not render anything if there are no products
  }

  const settings = {
    infinite: products.length >= 4, // Infinite loop only if there are 4 or more products
    speed: 500,
    slidesToShow: 4, // Always show 4 slides
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: products.length >= 3, // Adjust infinite behavior for 3 products
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: products.length >= 2, // Adjust infinite behavior for 2 products
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true, // Allow infinite for small screens
        },
      },
    ],
  };

  if (loading) {
    // Display loading indicator while fetching
    return (
      <div className="flex justify-center items-center h-full">
      <span className="dot-animation">• • •</span>
      <style>{`
        .dot-animation {
          font-size: 2rem;
          display: inline-block;
          animation: dots 1.5s infinite linear;
        }
        @keyframes dots {
          0% { content: "•"; }
          33% { content: "• •"; }
          66% { content: "• • •"; }
          100% { content: "•"; }
        }
      `}</style>
    </div>
    );
  }

  return (
    <div id="idd" className="w-full md:pb-16" style={{ paddingTop: '40px' }}>
      <Heading heading="Men" />
      <Slider {...settings}>
      {products.map((product) => (
          <div
            className="px-2"
            key={product.product_Id}
            style={{
              width: "350px", // Fixed width
              height: "400px", // Fixed height to keep uniformity
            }}
          >
            <Product
              _id={product.product_Id}
              img={`${BASE_URL}${product.product_images[0]?.image}`} // Assuming the first image is the main image
              images={product.product_images}
              productName={product.product_name}
              price={product.price}
              promo_percentage={product.promo_percentage}
              discounted_price={product.discounted_price}
              colors={product.colors}
              badge={product.badge}
              des={product.description}
              gender={product.gender}
              season={product.season}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Men;
