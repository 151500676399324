import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const Commands = () => {
    const [commands, setCommands] = useState([]);
    const [selectedCommand, setSelectedCommand] = useState(null);
    
    useEffect(() => {
        fetchCommands();
    }, []);

    const fetchCommands = () => {
        axios.get(`${BASE_URL}/api/commands/list/`)
            .then(response => setCommands(response.data))
            .catch(error => console.error(error));
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this command?')) {
            axios.delete(`${BASE_URL}/api/commands/delete/${id}/`)
                .then(() => {
                    fetchCommands();  // Re-fetch commands after deletion
                    alert('Command deleted successfully');
                })
                .catch(error => console.error('Error deleting command:', error));
        }
    };

    const handleSelectCommand = (command) => {
        setSelectedCommand(command); // Set selected command
    };

    const handleCloseDetails = () => {
        setSelectedCommand(null); // Close details view
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">View Orders</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {commands.map(command => (
                    <div key={command.command_id} className="bg-white p-4 shadow-md rounded-lg">
                        <h2 className="text-xl font-semibold mb-2">Order #{command.command_id}</h2>
                        <p className="text-sm text-gray-600 mb-1"><strong>Client:</strong> {command.client_name}</p>
                        <button
                            onClick={() => handleSelectCommand(command)}
                            className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 py-2 rounded hover:bg-blue-700"
                        >
                            View Details
                        </button>
                        <button
                            onClick={() => handleDelete(command.command_id)}
                            className="ml-4 bg-gradient-to-r from-red-600 to-red-800 text-white px-4 py-2 rounded hover:bg-red-700"
                        >
                            Delete Order
                        </button>
                    </div>
                ))}
            </div>

            {/* Selected Command Details */}
            {selectedCommand && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg h-3/4 overflow-y-auto relative">
                        <button
                            onClick={handleCloseDetails}
                            className="absolute top-4 right-4 text-4xl font-bold text-gray-600 hover:text-gray-900"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl font-bold mb-4">Order Details #{selectedCommand.command_id}</h2>
                        <p><strong>Client:</strong> {selectedCommand.client_name}</p>
                        <p><strong>Email:</strong> {selectedCommand.email}</p>
                        <p><strong>Phone:</strong> {selectedCommand.number}</p>
                        <p><strong>City:</strong> {selectedCommand.city}</p>
                        <p><strong>Street:</strong> {selectedCommand.street}</p>
                        <p><strong>Total Amount:</strong> {selectedCommand.total_amount} TND</p>
                        <p><strong>Shipping Charge:</strong> {selectedCommand.shipping_charge} TND</p>

                        <div className="mt-4">
                            <h3 className="text-md font-medium mb-2">Products:</h3>
                            {selectedCommand.command_products.map((item, index) => (
                                <div key={index} className="mb-2">
                                    <img
                                        src={`${BASE_URL}${item.product.product_images[0]?.image}`}
                                        alt={item.product.product_name}
                                        className="w-20 h-20 object-cover rounded-md mb-2"
                                    />
                                    <p><strong>Product Name:</strong> {item.product.product_name}</p>
                                    <p><strong>Price:</strong> {item.product.discounted_price} TND</p>
                                    <p><strong>Size:</strong> {item.size}</p>
                                    <p><strong>Color:</strong> {item.color}</p>
                                    <p><strong>Quantity:</strong> {item.quantity}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
       
    );
};

export default Commands;
