import React, { useEffect, useState } from "react"; 
import { Link } from "react-router-dom";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
const BASE_URL = process.env.REACT_APP_API_URL;
const YearProduct = () => {
  const [product, setProduct] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/products/product_of_the_year/`);
        if (response.ok) {
          const data = await response.json();
          setProduct(data); // Set the product data
        } else {
          setError("");
        }
      } catch (error) {
        console.error("Error fetching product of the year:", error);
        setError("Failed to load product of the year.");
      }
    };

    fetchProduct();
  }, []);

  if (error) {
    return <div>{error}</div>; // Show error if something went wrong
  }

  if (!product) {
    return <div></div>; // Show loading state while fetching
  }

  return (
    <Link to="/shop">
      <div className="w-full mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont flex flex-col md:flex-row items-center md:items-start" style={{ backgroundColor: '#f5f5f5' }}>
        
         {/* Image for small screens on top and larger screens to the left */}
         <div className="lg:ml-24 w-full md:w-1/4 sm:w-[300px] h-[300px] md:h-[400px] flex justify-center">
          <Image
            className="w-full h-full object-contain" // Changed from object-cover to object-contain
            imgSrc={`${BASE_URL}${product.product_images[0]?.image}`} // Use product image or fallback to default
            layout="fill"
            objectFit="contain" // Ensure full image is visible while resized
            sizes="100vw"
            priority
          />
        </div>

        {/* Content - Positioned below the image on small screens, next to it on larger screens */}
        <div className="lg:ml-64 w-full md:w-2/3 xl:w-1/2 h-auto md:h-80 px-4 md:px-0 py-6 md:py-0 flex flex-col items-center md:items-start gap-6 justify-center">
          <h1 className="text-2xl md:text-3xl font-semibold text-primeColor">
            Product of The Year
          </h1>
          <p className="text-sm md:text-base font-normal text-primeColor max-w-[600px] text-center md:text-left">
          {product.description || "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat cupiditate modi amet! Facilis, aperiam quaerat."} 
          </p>
          <div className="w-full flex justify-center md:justify-start">
            <ShopNow />
          </div>
        </div>
        
      </div>
    </Link>
  );
};

export default YearProduct;
