import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { ariabrandx } from "../../assets/images";
import { motion } from "framer-motion";
const Sidebar = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isSidenavOpen, setIsSidenavOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 667) {
                setIsSidebarVisible(false); // Hide sidebar for small screens
            } else {
                setIsSidebarVisible(true);  // Show sidebar for larger screens
                setIsSidenavOpen(false); // Close sidenav if it was open
            }
        };

        handleResize(); // Check size on initial render
        window.addEventListener('resize', handleResize); // Add resize event listener

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
        };
    }, []);

    return (
      <div className={`bg-black text-gray-200 bg-opacity-80 z-50 h-full ${isSidebarVisible ? 'w-[150px] lg:w-[300px]' : 'w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200'}`}>
           
      {isSidebarVisible && (
        <div> 
            {/* <img
        className="mt-4 ml-8 w-40 mb-6"
        src={ariabrandx}
        alt="logoLight"
      /> */}
          <nav className="flex flex-col items-start p-4 space-y-6">
              <Link 
                  to="/admin" 
                  className="flex items-center space-x-2 hover:text-gray-400 hover:scale-105 duration-300"
              >
                  <span>Dashboard</span>
              </Link>
              <Link 
                  to="/admin/products" 
                  className="flex items-center space-x-2 hover:text-gray-400 hover:scale-105 duration-300"
              >
                  <span>Products</span>
              </Link>
              <Link 
                  to="/admin/commands" 
                  className="flex items-center space-x-2 hover:text-gray-400 hover:scale-105 duration-300"
              >
                  <span>Commands</span>
              </Link>
              <Link 
                  to="/admin/contacts" 
                  className="flex items-center space-x-2 hover:text-gray-400 hover:scale-105 duration-300"
              >
                  <span>Contacts</span>
              </Link>
              <Link 
                  to="/admin/ImageManagement" 
                  className="flex items-center space-x-2 hover:text-gray-400 hover:scale-105 duration-300"
              >
                  <span>ImageManagement</span>
              </Link>
          </nav>
          </div>
      )}



            <HiMenuAlt2
              onClick={() => setIsSidenavOpen(true)}
              className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
            />
            {isSidenavOpen&& (
              <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                <motion.div
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="w-[80%] h-full relative"
                >
                  <div className="w-full h-full bg-primeColor p-6">
                    {/* <img
                      className="w-40 mb-6"
                      src={ariabrandx}
                      alt="logoLight"
                    /> */}
                  
                  <nav className="flex flex-col items-start space-y-6">
                        <Link to="/admin" className="flex items-center space-x-2" onClick={() => setIsSidenavOpen(false)}>
                            <span>Dashboard</span>
                        </Link>
                        <Link to="/admin/products" className="flex items-center space-x-2" onClick={() => setIsSidenavOpen(false)}>
                            <span>Products</span>
                        </Link>
                        <Link to="/admin/commands" className="flex items-center space-x-2" onClick={() => setIsSidenavOpen(false)}>
                            <span>Commands</span>
                        </Link>
                        <Link to="/admin/contacts" className="flex items-center space-x-2" onClick={() => setIsSidenavOpen(false)}>
                            <span>Contacts</span>
                        </Link>
                        <Link to="/admin/ImageManagement" className="flex items-center space-x-2" onClick={() => setIsSidenavOpen(false)}>
                            <span>ImageManagement</span>
                        </Link>
                        
                    </nav>
                  </div>
                  <span
                    onClick={() => setIsSidenavOpen(false)}
                    className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                  >
                    <MdClose />
                  </span>
                </motion.div>
              </div>
            )}
        </div>
    );
};

export default Sidebar;


