import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { deleteItem } from "../../redux/shopSlice";

const ItemCard = ({ item }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteItem({ id: item._id, color: item.color, size: item.size })); // Pass id, color, and size
  };

  return (
    <div className="w-full grid grid-cols-7 items-center gap-4 mb-4 border py-2 px-4">
      {/* Product column (2 cols) */}
      <div className="col-span-2 flex items-center gap-4">
        <ImCross
          onClick={handleDelete}
          className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
        />
        <img className="w-24 h-24 object-cover" src={item.image} alt="productImage" />
        <h1 className="font-titleFont font-semibold">{item.name}</h1>
      </div>

      {/* Price column */}
      <div className="col-span-1 text-lg font-semibold text-center">
        {item.discounted_price} TND
      </div>

      {/* Color column */}
      <div className="col-span-1 text-lg text-center">
        <p>{item.color}</p>
      </div>

      {/* Size column */}
      <div className="col-span-1 text-lg text-center">
        <p>{item.size}</p>
      </div>

      {/* Quantity column */}
      <div className="col-span-1 text-lg text-center">
        <p>{item.quantity}</p>
      </div>

      {/* Subtotal column */}
      <div className="col-span-1 text-lg font-bold text-center">
      {(item.quantity * item.discounted_price).toFixed(2)} TND
      </div>
    </div>
  );
};

export default ItemCard;
