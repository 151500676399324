import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { NavLink,useLocation} from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  return (
    <div className="w-full bg-[#F5F5F3] pt-12 pb-12">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title=" Call Us" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
            <p><strong className="mr-2">(+216) </strong> 22664668</p>
            </p>
            
          </div>
        </div>
        <div>
          <FooterListTitle title="Shop" />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
            <NavLink
             to="/shop"
             state={{ data: location.pathname.split("/")[1] }}
              >
               Choose a product 
                </NavLink>
              
            </li>
            
          </ul>
        </div>
        <div>
          <FooterListTitle title="Your Orders" />
          <ul className="flex flex-col gap-2">
            
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
            <NavLink
              to="/cart"
              state={{ data: location.pathname.split("/")[1] }}
               >
               Your Cart
               </NavLink>
            </li>
           
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Our Adress" />
          <div className="ml-w-full">
          <ul className=" flex items-center gap-3">
              <a
                href="https://www.instagram.com/"
                // aria.brandx/
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
            </ul>
            
              
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
