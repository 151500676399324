import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";

const BASE_URL = process.env.REACT_APP_API_URL;
const Category = ({ updateProducts, gender, closeSidebar }) => {
  const [showBrands, setShowBrands] = useState(false); // Initially closed
  const [selectedCategory, setSelectedCategory] = useState("Winter");

  const brands = [
    { _id: 9006, title: "Winter", base: "#3b82f6" },
    { _id: 9007, title: "Summer", base: "#f59e0b" },
    { _id: 9008, title: "Party", base: "#22c55e" },
  ];

  const fetchCategoryProducts = (category) => {
    return fetch(`${BASE_URL}/api/products/gender/${gender}/season/${category}/`)
      .then((response) => response.json())
      .then((data) => {
        updateProducts(data);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    fetchCategoryProducts(category);
    // Hide the sidebar if the screen is small
    if (window.innerWidth < 1024) {
      closeSidebar(); // Call this function to close the sidebar when a category is selected
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <div onClick={() => setShowBrands(!showBrands)} className="cursor-pointer">
        <NavTitle title={`Shop by ${gender} Category`} icons={true} />
      </div>

      {showBrands && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-gray-600">
            {brands.map((item) => (
              <li
                key={item._id}
                className={`border-b pb-2 flex items-center gap-3 hover:text-blue-500 hover:border-gray-400 transition-all duration-300 cursor-pointer ${
                  selectedCategory === item.title ? "text-blue-500 font-semibold" : ""
                }`}
                onClick={() => handleCategorySelect(item.title)}
              >
                <span style={{ background: item.base }} className="w-4 h-4 rounded-full"></span>
                {item.title}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Category;
